@import "../../styles/mixins.scss";
@import "../../styles/variables.scss";

.container {
  position: relative;
  margin: 0 auto;
  width: 80%;
  max-width: 1200px;
  height: 100%;
}

@media only screen and (max-width: $sm) {
  .container {
    width: 90%;
  }
}
