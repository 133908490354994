@import "../../styles/variables.scss";

.primary:hover {
  path {
    fill: #eb9309;
  }
}

.secondary:hover {
  path {
    fill: $color-orange-dark;
  }
}
