@import url("https://fonts.googleapis.com/css?family=Roboto+Mono");
@import "../../styles/variables.scss";

.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh;
  gap: 48px;
}

.container {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
}
.text {
  color: $color-gray;
  font-size: 24px;
  letter-spacing: 0.2px;
  //   font-family: "Roboto Mono", monospace;
}

.handle {
  background: $color-orange;
  width: 14px;
  height: 30px;
  animation: blink 0.4s ease-in-out infinite;
  animation-direction: alternate-reverse;
}

@keyframes blink {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
