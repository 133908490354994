@import "../../styles/mixins.scss";
@import "../../styles/variables.scss";

$grid-padding-top: 3rem;

.footer {
  width: 100%;
  min-height: 300px;
  color: rgba(249, 249, 249, 1);
  background-color: $color-gray;
}

.grid {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1.5fr;
  width: 100%;
  height: 100%;
  padding: $grid-padding-top 0;
}

.logo-container {
  @include centered-flex;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 100px;
  gap: 1rem;
  img {
    width: 100px;
  }
  span {
    height: 100%;
    padding: 10px 0;
    font-size: 20px;
    width: 100px;
  }
}

.list {
  width: 100%;
  height: 100%;
  text-align: left;
  h4 {
    font-weight: 800;
    margin-bottom: 1rem;
  }

  ul {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    li {
      display: flex;
      align-items: center;
      gap: 11px;
      list-style: none;
      text-align: inherit;
      font-size: 14px;
      line-height: 1rem;
      margin-bottom: 11px;
      a {
        text-decoration: none;
        color: inherit;
      }
    }
  }
}

.social-icons-container {
  display: flex;
  align-items: center;
  gap: 6px;
  margin-top: 10px;
}

.scroll-up {
  position: absolute;
  top: $grid-padding-top;
  right: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  img:hover {
    transform: scale(1.05);
  }
  span {
    transform: rotate(-90deg);
    user-select: none;
  }
}

.copyright {
  position: absolute;
  bottom: 1rem;
  display: block;
  width: 100%;
  background-color: $color-gray;
  text-align: left;
}

@media only screen and (max-width: $sm) {
  .footer {
    min-height: 360px;
  }
  .grid {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 100px 1fr;
    padding: $grid-padding-top 0;
  }

  .logo-container {
    grid-column: span 2;
  }

  .scroll-up {
    right: 1rem;
  }

  .copyright {
    padding: 0 1.5rem;
  }
}

// TODO. Make icon white when hovering

.social-icons {
  @include centered-flex;
  justify-content: flex-start;
  gap: 0.5rem;
}
