.cursor {
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  border: 1px solid rgba(38, 38, 38, 0.7);
  border-radius: 50px;
  z-index: 1000000;
  margin: -25px;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.07s linear all;
  transition: 0.07s linear all;
  pointer-events: none;
  box-sizing: border-box;
}

.zoom {
  -webkit-transform: scale(2);
  transform: scale(2);
}

.pointer {
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  //   margin: -5px;
  background: #ff5f3d;
  border-radius: 50px;
  z-index: 1000000;
  pointer-events: none;
}

.animated {
  animation: cursorClick 700ms;
}

@keyframes cursorClick {
  from,
  to {
    border: 1px solid rgba(38, 38, 38, 0.7);
    transform: scale(1);
  }
  60% {
    border: 1px solid rgba(38, 38, 38, 0);
    transform: scale(2);
  }
  70% {
    border: 1px solid rgba(38, 38, 38, 0);
    transform: scale(1.4);
  }
}
