@import "../../styles/mixins.scss";
@import "../../styles/variables.scss";

.grid {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, minmax(270px, 700px));
  grid-template-rows: auto;
  grid-template-areas:
    "text1 img1"
    "text2 img1"
    "img2 text3"
    "img2 text3"
    "text4 img3";
  grid-gap: 4rem 2rem;
  align-items: flex-start;
  justify-content: space-between;

  img {
    max-width: 100%;
    border-radius: 10px;
  }
}

.text1 {
  grid-area: text1;
  width: 80% !important;
}

.text2 {
  grid-area: text2;
}
.text3 {
  grid-area: text3;
}

.text4 {
  grid-area: text4;
  h3 {
    font-size: 28px !important;
  }
}

.text1,
.text2,
.text3,
.text4 {
  max-width: 570px;
}

.img1 {
  grid-area: img1;
}
.img2 {
  grid-area: img2;
}

.img3 {
  grid-area: img3;
  border-radius: 10px;
}

@media only screen and (max-width: 1100px) {
  .grid {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-gap: 1rem;
    grid-template-areas:
      "text1"
      "img1"
      "text2"
      "img2"
      "text3"
      "text4"
      "img3";
  }
  .img1,
  .img2,
  .img3 {
    width: 100%;
  }

  .text1,
  .text2,
  .text3,
  .text4 {
    max-width: 100%;
    width: 100% !important;
    h3 {
      font-size: 20px !important;
    }
  }
}
