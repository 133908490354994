@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.container {
  position: relative;
  width: 100%;
  height: 100vh;
  min-height: 700px;
  color: $color-gray;
}

.background {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url("../../assets/images/hero.webp");
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
}

.white-gradient {
  @include centered-flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  background: linear-gradient(178.15deg, rgba(245, 244, 239, 0) -12.25%, #f5f4ef 67.12%);
}

.hero-content {
  display: flex;
  align-items: center;
  height: calc(100% - 116px); // minus height of the header with its margins;
  gap: 40px;
  justify-content: center;

  .logo {
    width: 400px;
    z-index: 1;
  }

  .text {
    max-width: 570px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    z-index: 1;

    h1 {
      font-family: "Gilroy-Heavy";
      font-style: normal;
      font-weight: 900;
      font-size: 40px;
      line-height: 51px;
      text-transform: uppercase;
      margin: 0;
      span:first-child {
        color: $color-orange;
        margin-right: 16px;
      }
    }

    h2 {
      font-size: 36px;
      font-family: "Gilroy-UltraLight";
    }
    p {
      font-family: "Gilroy-Regular";
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
    }
  }
}

@media only screen and (max-width: $md) {
  .logo {
    display: none;
  }
  .text {
    h1 {
      text-align: center;
    }
    .subheading {
      text-align: center;
      margin-bottom: 29px;
    }
  }
}

@media only screen and (max-width: $sm) {
  .container {
    height: 735px;
    overflow: hidden;
  }

  .background {
    height: 100%;
  }

  .hero-content {
    padding-bottom: 70px;
  }
  .text {
    h1,
    h2 {
      font-size: 24px !important;
      line-height: 31px !important;
    }
    .subheading {
      text-align: center;
      margin-bottom: 29px;
    }
  }
}
