@import "../../styles/mixins.scss";
@import "../../styles/variables.scss";

.section {
  @include centered-flex;
  width: 100%;
  height: 293px;
  background-image: url("../../assets/images/submit-offer-background.webp");
  background-size: cover;
  background-repeat: no-repeat;
}

.content {
  @include centered-flex;
  justify-content: space-between;
  gap: 2rem;
  width: 100%;
  height: 100%;
  color: #ffffff;
}

.logo-container {
  @include centered-flex;
  gap: 2rem;
  height: 100%;
  width: 50%;
  color: #ffffff;
  img {
    width: 90px;
    @include beat-animation;
  }
  span {
    font-size: 36px;
  }
}

.submit {
  @include centered-flex;
  flex-direction: column;
  width: 50%;

  h2,
  p {
    margin: 0 0 1.5rem 0;
    max-width: 35rem;
  }

  h2 {
    font-family: "Gilroy-Heavy", sans-serif;
    font-size: 29px;
  }
  p {
    font-size: 14px;
    text-align: center;
    line-height: 22px;
  }
}

@media only screen and (max-width: $sm) {
  .section {
    background-image: url("../../assets/images/submit-offer-background-sm.webp");
    background-position: center;
  }

  .logo-container {
    display: none;
  }

  .submit {
    align-items: flex-start;
    width: 100%;
    h2,
    p {
      text-align: left;
      margin: 0 0 1rem 0;
    }
  }
}
