@import "../../styles/variables.scss";

.section {
  width: 100%;
  background-image: linear-gradient(180deg, $color-main-bg, rgba(255, 255, 255, 0)),
    url("../../assets/images/about-section-bg.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin: 5rem 0 0 0;
}

.section-header {
  font-family: "Gilroy-Heavy", sans-serif;
  color: $color-gray;
  margin: 2rem 0 6rem 0;
  text-align: left;
  font-size: 40px;
  font-weight: 900;
  text-transform: uppercase;
  max-width: 722px;
}

@media only screen and (max-width: $sm) {
  .section-header {
    font-size: 24px;
    padding: 0 1.5rem;
    margin: 1rem 0 3rem 0;
  }
  .section {
    margin: 0;
    background-position: bottom;
    background-size: 200%;
  }
}

@media only screen and (max-width: $xs) {
  .section-header {
    padding: 0;
  }
}
