.container {
  position: fixed;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  bottom: 0;
  width: 100%;
  min-height: 80px;
  height: fit-content;
  z-index: 300;
  transform: translateY(100%);
  animation: toast ease 0.3s forwards 4s;
  p {
    margin-right: 1rem;
    font-size: 14px;
  }
  button {
    background: none;
    font-family: inherit;
    border: 1px solid white;
    padding: 0.5rem 1rem;
    color: white;
  }
}

@keyframes toast {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0%);
  }
}
