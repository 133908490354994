@import "../../styles/mixins.scss";
@import "../../styles/variables.scss";

.missions-container {
  margin: 8rem 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  grid-gap: 2rem;
  width: 100%;
}

.tabs {
  @include centered-flex;
  gap: 1rem;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.active-tab,
.inactive-tab {
  @include centered-flex;
  position: relative;
  font-family: "Gilroy-Heavy", sans-serif;
  font-size: 24px;
  font-weight: 900;
  width: 100%;
  height: 107px;
  background: #f5f4ef;
  box-shadow: 0px 4px 22px rgba(206, 206, 206, 0.25);
  overflow: hidden;

  h3 {
    font-size: inherit;
  }
  h3:first-child {
    margin-right: 0.5rem;
  }

  span:nth-of-type(1) {
    position: absolute;
    background: $color-orange-gradient;
    height: 3px;
    width: 200%;
    top: 0;
    transform: translateX(-100%);
    animation: to-right 1s linear infinite;
  }

  span:nth-of-type(2) {
    position: absolute;
    background: $color-orange-gradient;
    height: 200%;
    width: 3px;
    right: 0;
    transform: translateY(-100%);
    animation: to-bottom 1s linear infinite;
    animation-delay: 0.25s;
  }
  span:nth-of-type(3) {
    position: absolute;
    background: $color-orange-gradient;
    height: 3px;
    width: 200%;
    bottom: 0;
    transform: translateX(100%);
    animation: to-left 1s linear infinite;
    animation-delay: 0.5s;
  }
  span:nth-of-type(4) {
    position: absolute;
    background: $color-orange-gradient;
    height: 200%;
    width: 3px;
    left: 0;
    transform: translateY(100%);
    animation: to-top 1s linear infinite;
    animation-delay: 0.75s;
  }
}

.active-tab {
  background-color: #fffef9;
  color: $color-orange;
  h3:first-child {
    color: $color-gray;
    font-size: 24px;
  }

  span {
    transform: translateY(0);
  }
}

.inactive-tab {
  color: $color-gray-light;
}

.preview {
  height: 100%;
  width: 100%;
  background: #fffef9;
  box-shadow: 0px 4px 22px rgba(206, 206, 206, 0.25);
  border-radius: 10px;
  padding: 3rem 5rem;
  div {
    animation: fadein;
    animation-duration: 0.4s;
    animation-timing-function: ease-in-out;
  }

  h2 {
    font-family: "Gilroy-heavy", sans-serif;
    text-align: left;
    color: $color-orange;
    font-size: 40px;
    margin-bottom: 16px;
    span:first-child {
      color: $color-gray;
      margin-right: 0.7rem;
    }
  }

  p {
    font-family: "Gilroy-Regular";
    font-weight: 400;
    text-align: left;
    font-size: 14px;
    line-height: 150%;
  }
}

@media only screen and (max-width: $md) {
  .missions-container {
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: $sm) {
  .missions-container {
    margin: 4rem 0;
  }

  .tabs {
    height: fit-content;
  }

  .active-tab,
  .inactive-tab {
    height: 70px;
  }

  .inactive-tab {
    color: #828282;
  }
  .preview {
    padding: 25px 12px;
    h2 {
      font-size: 24px;
    }
  }
}

@keyframes fadein {
  from {
    opacity: 0%;
  }
  to {
    opacity: 100%;
  }
}

@keyframes to-right {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(100%);
  }
}

@keyframes to-bottom {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(100%);
  }
}

@keyframes to-left {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}

@keyframes to-top {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(-100%);
  }
}
