@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.button {
  @include centered-flex;
  font-family: "Gilroy-regular", sans-serif;
  background: none;
  outline: none;
  border: none;
  height: 50px;
  width: 240px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
}

.button:hover {
  background-color: $color-orange-light;
}

.contained {
  background-color: $color-orange;
  color: $color-gray;
}

.outlined {
  background: rgba(255, 255, 255, 0);
  color: white;
  border: 1px solid $color-gray;
}

.fullWidth {
  width: 100%;
}
