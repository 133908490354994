@import "../../styles/mixins.scss";
@import "../../styles/variables.scss";

.navigation-desktop {
  display: flex;
  ul {
    @include centered-flex;
    li {
      list-style: none;
      margin: 0 1rem;
      a {
        text-decoration: none;
        color: inherit;
        text-transform: uppercase;
      }
    }
    li:hover {
      border-bottom: 1px solid $color-gray;
    }
  }
}
