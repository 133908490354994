$color-gray: rgba(51, 51, 51, 1);
$color-gray-light: #bdbdbd;
$color-body: rgba(245, 244, 239, 255);

$color-orange: rgba(235, 147, 9, 1);
$color-orange-gradient: radial-gradient(
  rgba(235, 147, 9, 1),
  rgba(235, 147, 9, 0.1),
  rgba(235, 147, 9, 0.1)
);
$color-orange-dark: rgba(167, 103, 2, 1);
$color-orange-light: rgba(255, 182, 68, 1);

$color-main-bg: rgba(245, 244, 239, 255);

$md: 1400px;
$sm: 1000px;
$xs: 500px;
