@import "./styles/variables.scss";
@import "./styles/mixins.scss";

@font-face {
  font-family: "Gilroy-Thin";
  font-display: swap;
  src: url("./assets/fonts/Gilroy-Thin.woff") format("woff");
}

@font-face {
  font-family: "Gilroy-UltraLight";
  font-display: swap;
  src: url("./assets/fonts/Gilroy-UltraLight.woff") format("woff");
}

@font-face {
  font-family: "Gilroy-Light";
  font-display: swap;
  src: url("./assets/fonts/Gilroy-Light.woff") format("woff");
}

@font-face {
  font-family: "Gilroy-Medium";
  font-display: swap;
  src: url("./assets/fonts/Gilroy-Medium.woff") format("woff");
}

@font-face {
  font-family: "Gilroy-Regular";
  font-display: swap;
  src: url("./assets/fonts/Gilroy-Regular.woff") format("woff");
}

@font-face {
  font-family: "Gilroy-Heavy";
  font-display: swap;
  src: url("./assets/fonts/Gilroy-Heavy.woff") format("woff");
}

* {
  margin: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: none;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: rgba(245, 244, 239, 255);
  font-family: "Gilroy-Regular", sans-serif;
  overflow-x: hidden;
}

a {
  text-decoration: none;
  color: inherit;
}
