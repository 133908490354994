@import "../../styles/mixins.scss";
@import "../../styles/variables.scss";

.container {
  margin: 2rem 0;
  span {
    text-align: left;
    font-size: 24px;
    display: flex;
    margin-bottom: 1rem;
  }
  article {
    width: 100%;
    @include centered-flex;
    flex-direction: column;
    align-items: flex-start;
    h2,
    h3 {
      color: $color-gray;
      text-transform: uppercase;
      font-family: "Gilroy-Heavy", sans-serif;
      font-weight: 900;
      font-size: 40px;
      margin-bottom: 1rem;
      text-align: left;
    }
    p {
      text-align: left;
      line-height: 150%;
      font-size: 14px;
      margin-bottom: 1rem;
    }
    ul {
      margin-bottom: 1rem;
      li {
        text-align: left;
      }
    }
  }
}

@media only screen and (max-width: $sm) {
  .container {
    span {
      font-size: 16px;
    }
    article {
      h2,
      h3 {
        font-size: 24px;
      }
      p {
        font-size: 14px;
      }
      ul {
        li {
          font-size: 14px;
        }
      }
    }
  }
}
