@import "../../styles/mixins.scss";
@import "../../styles/variables.scss";

.navigation-mobile {
  position: fixed;
  display: grid;
  align-items: center;
  grid-template-columns: 2fr 2fr;
  grid-template-rows: 3fr 1fr;
  grid-template-areas:
    "nav socials"
    "descr descr";
  background: rgba(255, 255, 255, 1);
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 14;
  transform: translateX(100%);
  transition: ease-in-out 0.4s;

  ul {
    @include centered-flex;
    grid-area: nav;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
    height: 70%;
    width: 100%;
    border-right: 1px solid $color-gray;
    li {
      list-style: none;
      font-size: 24px;
      text-align: left;
      font-weight: 400;
      a {
        text-decoration: none;
        color: inherit;
      }

      a:hover {
        border-bottom: 1px solid black;
      }
    }
  }
}

.show {
  transform: translateX(0);
}

.close {
  position: absolute;
  right: 40px;
  top: 50px;
}

.description {
  grid-area: descr;
  @include centered-flex;
  gap: 1rem;
  flex-direction: column;
  align-items: flex-start;
  padding: 41px 25px;

  .logo {
    @include centered-flex;
    gap: 1rem;
    height: 50px;
    img {
      height: 100%;
    }
    h2 {
      width: 5rem;
      font-size: 14px;
      text-align: left;
    }
  }

  .links {
    display: flex;
    font-size: 14px;
    width: 100%;
    justify-content: space-between;
    ul {
      border: none;
      padding: 0;
      display: flex;
      flex-direction: column;
      gap: 11px;
      width: max-content;
      li {
        font-size: 14px;
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }
    .right-aligned {
      align-items: flex-end;
    }
  }
}

.social-icons {
  grid-area: socials;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  a {
    @include centered-flex;
    width: 60%;
  }
}
