@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

$burger-font-size: 1.2rem; // Scale is using font-size in rems;
$burger-color: $color-gray; //  #f0ffff;
$burger-borderRadius: 1em;

.header {
  @include centered-flex;
  position: fixed;
  top: 0;
  color: $color-gray;
  width: 100%;
  z-index: 2;
  margin-top: 20px;
  font-size: 14px;
}

.on-homepage {
  padding: 20px 0;
  top: 0;
  margin: 0;

  .header-container {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    background: #f5f4ef;
  }
}

.header-container {
  @include centered-flex;
  justify-content: space-between;
  background: #f5f4eff1;
  height: 4.75rem;
  padding: 0 4rem;
}

.login-container {
  @include centered-flex;
  gap: 1rem;

  a {
    text-decoration: none;
    color: $color-gray;
  }
}

.logo-container {
  @include centered-flex;
  gap: 1rem;
  text-decoration: none;
  color: inherit;
  img {
    @include beat-animation;
  }
}

.menu-button-container {
  @include centered-flex;
  justify-content: flex-end;
  width: 120px;
  height: 16px;
}

.burger {
  font-size: $burger-font-size;
  z-index: 1000;
  & > div {
    width: 2.25em;
    height: 0.375em;
    border-radius: $burger-borderRadius;
    background: $burger-color;
    &::before,
    &::after {
      transition: font-size 0s;
      content: "";
      display: block;
      position: absolute;
      width: 2.25em;
      height: 0.375em;
      background: $burger-color;
      border-radius: $burger-borderRadius;
    }
    &::before {
      margin-top: -0.8em;
    }
    &::after {
      margin-top: 0.8em;
    }
  }

  transition: all 0.7s, font-size 0s;
  transition-timing-function: cubic-bezier(0.68, -0.35, 0.265, 1.35);
  & > div {
    transition: all 0.1s 0.3s, font-size 0s;
    &::before,
    &::after {
      transition: all 0.3s 0.2s, font-size 0s;
    }
  }
  &.toggled {
    transform: rotate(180deg);
    position: fixed;
    > div::before {
      transform: rotate(45deg) translate(0.4em, 0.75em);
      background: $color-gray;
    }
    > div {
      background: rgba(255, 255, 255, 0);
    }
    > div::after {
      transform: rotate(-45deg) translate(0.4em, -0.75em);
      background: $color-gray;
    }
  }
}

@media only screen and (max-width: $sm) {
  .header {
    padding: 0;
  }

  .burger {
    z-index: 1000;
  }

  .on-homepage {
    width: 111%;
    left: -5.5%;
  }

  .header-container {
    padding: 0 1rem;
    height: 105px;
    background: rgba(245, 244, 239, 0.4);
  }

  .sitename {
    display: none;
  }
}
