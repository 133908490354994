@import "../../styles/variables.scss";

.container {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr minmax(300px, 460px);
  grid-template-areas: "text image";
  grid-gap: 1rem;
  padding: 44px 49px 38px 49px;
  background-color: #fffef9;
  width: 100%;
  margin-top: 120px;
  box-shadow: 0px 4px 22px rgba(206, 206, 206, 0.25);
  border-radius: 10px;
}

.text {
  grid-area: text;
  h2 {
    font-family: "Gilroy-Heavy", sans-serif;
    text-transform: uppercase;
    color: $color-gray;
    font-size: 40px;
    margin-bottom: 6px;
    line-height: 51px;
  }
  h4 {
    font-family: "Gilroy-Regular", sans-serif;
    text-transform: uppercase;
    color: rgba(235, 147, 9, 1);
  }
  span {
    height: 2.44px;
    width: 40.62px;
    background: black;
    display: flex;
    margin: 1rem 0;
  }
  p {
    font-family: "Gilroy-Regular";
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    margin-bottom: 10px;
  }
}

.image {
  grid-area: image;
  justify-self: center;
  width: 433px;
  height: 433px;
  object-fit: contain;
  overflow: hidden;
  border: 2px solid #eb9309;
  border-radius: 50%;
  img {
    width: 100%;
  }
}

@media only screen and (max-width: $md) {
  .container {
    padding: 34px 39px 28px 39px;
  }
  .image {
    max-width: 400px;
    max-height: 400px;
  }
}

@media only screen and (max-width: 1150px) {
  .container {
    grid-template-columns: 1fr;
    grid-template-rows: fit-content 1fr;
    grid-template-areas:
      "image"
      "text";
  }

  .image {
    max-width: 400px;
    max-height: 400px;
    width: 70vw;
    height: 70vw;
  }
}

@media only screen and (max-width: $sm) {
  .container {
    padding: 36px 24px;
  }
  .text {
    h2 {
      font-size: 24px;
    }
  }
  .image {
    max-width: 300px;
    max-height: 300px;
  }
}

@media only screen and (max-width: $xs) {
  .container {
    margin-top: 60px;
  }
}
