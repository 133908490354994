@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.container {
  width: 100%;
  height: 230px;
  margin-top: 100px;
  background-color: $color-orange;
  ul {
    @include centered-flex;
    justify-content: flex-start;
    padding: 2rem 0;
    li {
      @include centered-flex;
      text-align: left;
      list-style-type: none;
      height: 2rem;
      color: #fff;
      border-right: 1px solid #fff;
      padding: 0 1rem;
      font-size: 14px;

      a {
        text-decoration: none;
        text-align: left;
        color: inherit;
      }
    }

    li:first-child {
      padding-left: 0;
    }

    li:last-child {
      border: none;
      color: rgba(224, 224, 224, 1);
    }
  }

  h1 {
    display: flex;
    text-align: left;
    color: $color-gray;
    max-width: 600px;
    font-size: 40px;
  }
}

@media only screen and (max-width: $sm) {
  .container {
    min-height: 150px;
    height: fit-content;
    margin-top: 125px;
    ul {
      padding: 1rem 0;
    }
    h1 {
      font-size: 24px;
    }
  }
}
