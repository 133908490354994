@import "../../styles/variables.scss";
$grid-cell-size: 220px;

.circles-grid {
  display: grid;
  width: 100%;
  max-width: 1400px;
  justify-items: center;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin: 0 auto;
  grid-template-columns: repeat(5, $grid-cell-size);
  grid-template-rows: repeat(2, $grid-cell-size);
  grid-template-areas:
    "circle1 gap1 circle2 gap2 circle3"
    "gap3 circle4 gap4 circle5 gap5";
}

.circle1 {
  grid-area: circle1;
}

.circle2 {
  grid-area: circle2;
}

.circle3 {
  grid-area: circle3;
}

.circle4 {
  grid-area: circle4;
}

.circle5 {
  grid-area: circle5;
}

@media only screen and (max-width: $md) {
  .circles-grid {
    grid-template-columns: $grid-cell-size calc($grid-cell-size/1.5) $grid-cell-size;
    grid-template-rows: repeat(3, $grid-cell-size);
    grid-template-areas:
      "circle1 gap1 circle2"
      "circle3 gap3 circle4"
      "gap4 circle5 gap5";
    grid-gap: 5rem 0;
    padding: 0 1.5rem;
  }
  .circle5 {
    transform: translateY(-15%);
  }
}

@media only screen and (max-width: $sm) {
  .circles-grid {
    grid-template-columns: calc($grid-cell-size/1.5) calc($grid-cell-size/2) calc(
        $grid-cell-size/1.5
      );
    grid-template-rows: repeat(3, calc($grid-cell-size/1.5));
    grid-template-areas:
      "circle1 gap1 circle2"
      "circle3 gap3 circle4"
      "gap4 circle5 gap5";
    grid-gap: 2rem 0;
    padding: 0 1.5rem;
  }
  .circle5 {
    transform: translateY(-15%);
  }
}

@media only screen and (max-width: $xs) {
  .circles-grid {
    grid-template-columns: calc($grid-cell-size/2) calc($grid-cell-size/4) calc($grid-cell-size/2);
    grid-template-rows: repeat(3, calc($grid-cell-size/2));
    grid-template-areas:
      "circle1 gap1 circle2"
      "circle3 gap3 circle4"
      "gap4 circle5 gap5";
    grid-gap: 2rem 0;
    padding: 0;
  }
  .circle5 {
    transform: translateY(-10%);
  }
}
