@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

$circle-size: 320px;
$sm-circle-size: 200px;
$xs-circle-size: 158px;

.circle {
  @include centered-flex;
  flex-direction: column;
  background: $color-body;
  border-radius: 50%;
  height: $circle-size;
  width: $circle-size;
  box-shadow: inset 21.2689px -21.2689px 21.2689px rgba(186, 185, 182, 0.1),
    inset -21.2689px 21.2689px 21.2689px rgba(255, 255, 255, 0.1),
    rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  overflow: hidden;
}

.amount {
  color: $color-gray;
  font-size: 44px;
  font-weight: 1000;
  font-family: "Gilroy-Heavy", sans-serif;

  span:last-child {
    color: $color-orange;
    font-size: 40px;
    margin-left: 10px;
  }
}

.title {
  font-size: 28px;
  margin: 9px 0;
  color: $color-gray;
  text-align: center;
  width: 95%;
  font-weight: 400;
}

.text {
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  text-align: center;
  margin: 0;
  width: 85%;
  color: $color-gray;
}

@media only screen and (max-width: $sm) {
  .circle {
    height: $sm-circle-size;
    width: $sm-circle-size;
  }
  .amount {
    font-size: 20px;
    span:last-child {
      font-size: 20px;
      margin-left: 5px;
    }
  }
  .title {
    font-size: 14px;
    margin: 3px;
  }

  .text {
    font-size: 9px;
  }
}

@media only screen and (max-width: $xs) {
  .circle {
    height: $xs-circle-size;
    width: $xs-circle-size;
  }
  .amount {
    font-size: 20px;
    span:last-child {
      font-size: 20px;
    }
  }
  .title {
    font-size: 14px;
    margin: 3px;
  }

  .text {
    font-size: 8px;
  }
}
