@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.container {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  grid-gap: 2rem;
  width: 100%;
  height: 645px;
  overflow: hidden;
  .image-container {
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: 0%;
    }
  }
  .image-container2 {
    overflow: hidden;
    display: flex;
    justify-content: flex-end;
    justify-self: flex-end;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: 100%;
    }
  }
}

.youtube-circle {
  position: absolute;
  @include centered-flex;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 415px;
  height: 415px;
  border: 3px solid $color-orange;
  border-radius: 50%;
  transition: ease-out 0.2s;
  span {
    @include centered-flex;
    flex-direction: column;
    gap: 5px;
    width: 90%;
    height: 90%;
    background: $color-orange;
    border-radius: 50%;
    transition: ease-out 0.2s;
    h2 {
      font-family: "Gilroy-Heavy", sans-serif;
      font-size: 40px;
      color: #fff;
    }
    h2:nth-child(2) {
      color: $color-gray;
    }
  }
}

.youtube-circle:hover {
  border-color: $color-orange-dark;
}

@media only screen and (max-width: $sm) {
  .container {
    grid-gap: 1rem;
    height: 320px;
  }

  .youtube-circle {
    width: 280px;
    height: 280px;
    border: 6px solid $color-orange;

    span {
      width: 95%;
      height: 95%;
      h2 {
        font-size: 1.5rem;
        color: #fff;
        margin: 0;
      }
    }
  }
}
